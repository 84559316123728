(function() {
    'use strict';

    angular.module('aerosApp')
        .component('aflModalCarouselCheckboxesInput', {
            controller: aflModalCarouselCheckboxesInputController,
            templateUrl: '/static/javascript/directives/afl-modal-carousel/afl-checkboxes-input/afl-modal-carousel-checkboxes-input.html',
            bindings: {
                group: '=',
                editable: '=',
                fieldDescription: '=',
                model: '=',
                fieldName: '=',
                fullDotName: '=',
                required: '=',
                visible: '&',
                fieldChangeEventHandler: '&',
                parent: '='
            }
        });

    function aflModalCarouselCheckboxesInputController() {
        var $ctrl = this;
        // We want our $doCheck function to detect a change in the parent so we can reseed the
        // default values for our checkbox. We don't want to set defaults on initial display
        // because in 'view details' or edit mode the user may have values already set and we don't want to wipe them
        // out.
        $ctrl.oldParent = $ctrl.parent

        $ctrl.$doCheck = function $doCheck() {
            if ($ctrl.parent !== $ctrl.oldParent) {
                $ctrl.setDefaultValues();
                $ctrl.oldParent = $ctrl.parent;
            }
        };

        $ctrl.setDefaultValues = function setDefaultValues() {
            $ctrl.model[$ctrl.fieldName] = angular.copy($ctrl.fieldDescription.default);
        };

        $ctrl.isVisible = function () {
            return typeof $ctrl.visible !== "undefined" ?  $ctrl.visible : true;
        };
    }
})();
